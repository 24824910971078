import React from 'react';
import {ResultsModel} from '../model/results.model';


export const ResultsContext = React.createContext<ResultsModel>({
    userId: '',
    profession: '',
    onProfessionChange: (value: string) => {},
    agreeToTerms: false,
    onAgreeToTermsChange: (state: boolean) => {},
    licenseNumber: '',
    onLicenseNumberChange: (value: string) => {},
    pregnancy: false,
    onPregnancyClick: (state: boolean) => {},
    plannedPregnancy: false,
    onPlannedPregnancyClick: (state: boolean) => {}
});
