import React, {useEffect, useMemo, useState} from 'react';
import './App.css';
import './i18n';
import { Route, Routes } from 'react-router-dom';
import MainScreen from './components/screens/main-screen/MainScreen';
import FormBirthDate from './components/screens/subscreens/questionnaire/form-birth-date/FormBirthDate';
import {useTranslation} from 'react-i18next';
import QuestionnaireChoice from './components/screens/subscreens/questionnaire-choice/QuestionnaireChoice';
import QandA from './components/screens/subscreens/q-and-a/QandA';
import News from './components/screens/subscreens/news/News';
import Brochures from './components/screens/subscreens/brochures/Brochures';
import About from './components/screens/subscreens/about/About';
import Login from './components/screens/subscreens/questionnaire/login/Login';
import {ResultsContext} from './store/results-context';
import {ResultsModel} from './model/results.model';
import {v4 as uuidv4} from 'uuid';
import {Profession} from './components/screens/subscreens/questionnaire/login/Profession';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/pl';
import plPL from 'antd/lib/locale/pl_PL';
import {Locale} from 'antd/es/locale';

function App() {

    const {t, i18n} = useTranslation();

    const [locale, setLocale] = useState('pl');
    const [currentLocale, setCurrentLocale] = useState(plPL);

    const changeLanguage = (language: string) => {
        i18n.changeLanguage(language).then(() => {})
    }

    const changeLocale = (currentLocale: Locale) => {
        setLocale(currentLocale.locale)
        setCurrentLocale(currentLocale);
        dayjs.locale(locale === 'pl' ? 'pl' : 'en');
    }



    const [userId, setUserId] = useState<string>(uuidv4());
    const userIdCached = useMemo(() => ({userId, setUserId}), [userId, setUserId]);
    const [profession, setProfession] = useState<string>(Profession.doctor);
    const [agreeToTerms, setAgreeToTerms] = useState<boolean>(false);
    const [licenseNumber, setLicenseNumber] = useState<string>('');
    const [pregnancy, setPregnancy] = useState<boolean>(false);
    const [plannedPregnancy, setPlannedPregnancy] = useState<boolean>(false);

    const licenceNumberChangeHandler = (value: string) => {
        setLicenseNumber(value);
    }

    const pregnancyHandler = (value: boolean) => {
        setPregnancy(value);
    }

    const plannedPregnancyHandler = (value: boolean) => {
        setPlannedPregnancy(value);
    }

    const professionChangeHandler = (value: string) => {
        setProfession(value);
    }

    const agreeToTermsChangeHandler = (state: boolean) => {
        setAgreeToTerms(state);
    }

    useEffect(() => {}, [
        licenseNumber,
        agreeToTerms,
        profession,
        pregnancy,
        plannedPregnancy
    ]);

    const resultsCtx: ResultsModel = {
        userId: userIdCached.userId,
        profession: profession,
        onProfessionChange: professionChangeHandler,
        agreeToTerms: agreeToTerms,
        onAgreeToTermsChange: agreeToTermsChangeHandler,
        licenseNumber: licenseNumber,
        onLicenseNumberChange: licenceNumberChangeHandler,
        pregnancy: pregnancy,
        onPregnancyClick: pregnancyHandler,
        plannedPregnancy: plannedPregnancy,
        onPlannedPregnancyClick: plannedPregnancyHandler
    };

    return (
        <ResultsContext.Provider value={resultsCtx}>
            <Routes>
                <Route path="/" element={<MainScreen t={t} changeLanguage={changeLanguage} changeCurrentLocale={changeLocale} />}>
                    <Route index element={<QuestionnaireChoice t={t} />} />
                    <Route path="professionals" element={<Login t={t} />} />
                    <Route path="patients" element={<FormBirthDate t={t} currentLocale={currentLocale} />} />
                    <Route path="about" element={<About t={t} />} />
                    <Route path="brochures" element={<Brochures t={t} />} />
                    <Route path="qanda" element={<QandA t={t} />} />
                    <Route path="news" element={<News t={t} />} />
                </Route>
            </Routes>
        </ResultsContext.Provider>
    );
}

export default App;
