import React from 'react';

interface AboutProps {
    t: (key: string) => string;
}

const About: React.FC<AboutProps> = ({t}) => {

    return (
        <div className="App">
            about project
        </div>
    )

}

export default About;
