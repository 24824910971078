import React from 'react';
import './mainscreen.css';
import { FaHouse, FaCircleInfo, FaCalendarDays, FaClipboardList, FaClipboardQuestion, FaNewspaper } from 'react-icons/fa6';
import {Outlet, useNavigate} from 'react-router-dom';
import {Locale} from 'antd/es/locale';
import enUS from 'antd/lib/locale/en_US';
import plPL from 'antd/lib/locale/pl_PL';


interface MainScreenProps {
    t: (key: string) => string;
    changeLanguage: (language: string) => void;
    changeCurrentLocale: (locale: Locale) => void;
}


const MainScreen: React.FC<MainScreenProps> = ({t, changeLanguage, changeCurrentLocale}) => {

    const navigate = useNavigate();

    return (
        <div className="main-screen__wrapper">
            <div className="top-bar__wrapper">
                <div className="menu-home__wrapper" onClick={() => navigate('/')}>
                    <FaHouse />
                </div>
                <div className="menu-text__wrapper">
                    <div className="menu-item__wrapper" onClick={() => navigate('/about')}>
                        <FaCircleInfo color="var(--orange)" /> {t('menu_project')}
                    </div>
                    |
                    <div className="menu-item__wrapper">
                        <FaCalendarDays color="var(--orange)"/> {t('menu_calendar')}
                    </div>
                    |
                    <div className="menu-item__wrapper"  onClick={() => navigate('/brochures')}>
                        <FaClipboardList color="var(--orange)"/> {t('menu_brochures')}
                    </div>
                    |
                    <div className="menu-item__wrapper"  onClick={() => navigate('/qanda')}>
                        <FaClipboardQuestion color="var(--orange)"/> {t('menu_qanda')}
                    </div>
                    |
                    <div className="menu-item__wrapper"  onClick={() => navigate('/news')}>
                        <FaNewspaper color="var(--orange)"/> {t('menu_news')}
                    </div>
                </div>
                <div className="language-bar__wrapper">

                    <img className="flag__image"
                         alt="język polski"
                         onClick={() => {
                             changeLanguage('pl');
                             changeCurrentLocale(plPL);
                         }}
                         src={require('../../../assets/graphics/flagPL.png')}/>

                    <img className="flag__image"
                         alt="english version"
                         onClick={() => {
                             changeLanguage('en');
                             changeCurrentLocale(enUS);
                         }}
                         src={require('../../../assets/graphics/flagEN.png')}/>
                </div>
            </div>

            <div className="content__wrapper">
                <Outlet />
            </div>
        </div>
    )

}

export default MainScreen;
