export function validatePharmacistsLicenseNumber(licenseNumber: string) {

    const numbers = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10',
        '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22']

    if (licenseNumber.length !== 8 || !numbers.includes(licenseNumber.slice(0, 2))) {
        return false;
    }

    const weights = [3, 1, 3, 1, 3, 1, 3];
    const digits = licenseNumber.slice(0, 7).split('').map(Number);

    const weightedSum = digits.reduce((acc, digit, index) => {
        return acc + digit * weights[index];
    }, 0);

    const modulo = weightedSum % 10;

    const controlNumber = modulo === 0 ? modulo : (10 - modulo);

    return controlNumber === parseInt(licenseNumber[7], 10);
}


/*
Dla numeru EAN8
obliczamy sumę iloczynów cyfr przez ich wagi
– wagi wynoszą kolejno 3,1,3,1,3,1,3
– sumę dzielimy Modulo 10
– jeśli wynik jest różny od 0, to wynik odejmujemy od 10

===============
Przykład dla numeru EAN8 59012344
3 1 3 1 3 1 3 -> wagi
* 5 9 0 1 2 3 4 -> nr EAN8 (bez cyfry kontr. == 4)
——————
suma= (3*5 + 1*9 + 3*0 + 1*1 + 3*2 + 1*3 + 3*4) =
= (15 + 9 + 0 + 1 + 6 + 3 + 12) = 46
46 mod /10 = 6 ----> (10-6) cyfra kontrolna 4


===============
Przykład dla numeru EAN8 09009943
3 1 3 1 3 1 3 -> wagi
0 9 0 0 9 9 4 -> nr EAN8 (bez cyfry kontr. == 3)
——————
suma= (3*0 + 1*9 + 3*0 + 1*0 + 3*9 + 1*9 + 3*4) =
= (0 + 9 + 0 + 0 + 27 + 9 + 12) = 57
57 mod /10 = 7 ----> (10-7) cyfra kontrolna 3
 */
