import React from 'react';
import {FaHandHoldingMedical, FaPeopleGroup} from 'react-icons/fa6';
import './questionnairechoice.css';
import {useNavigate} from 'react-router-dom';

interface QuestionnaireChoiceProps {
    t: (key: string) => string;
}

const QuestionnaireChoice: React.FC<QuestionnaireChoiceProps> = ({t}) => {

    const navigate = useNavigate();

    return (
        <div className="questionnaire-choice-main__wrapper">
            <div className="box__wrapper" onClick={() => navigate('/professionals')}>
                {t('menu_for_professionals')} <FaHandHoldingMedical size={60}/>
            </div>
            <div className="box__wrapper" onClick={() => navigate('/patients')}>
                {t('menu_for_patients')} <FaPeopleGroup size={60}/>
            </div>
        </div>
    )

}

export default QuestionnaireChoice;
