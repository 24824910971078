import React, {useContext, useState} from 'react';
import './formbirthdate.css';
import {ConfigProvider, DatePicker} from 'antd';
import 'dayjs/locale/en';
import 'dayjs/locale/pl';
import {Locale} from 'antd/es/locale';
import SwitchComponent from '../../../../shared/switch/Switch.component';
import {ResultsContext} from '../../../../../store/results-context';


interface FormBirthDateProps {
    t: (key: string) => string;
    currentLocale: Locale;
}

const FormBirthDate: React.FC<FormBirthDateProps> = ({t, currentLocale}) => {

    const resultsCtx = useContext(ResultsContext);
    const [selectedDate, setSelectedDate] = useState(null);

    return (

        <div className="form-birth-date__wrapper">

            <div className="label-input__wrapper">
                <div className="question__wrapper">
                    <div className="label__wrapper">
                        {t('screen_birth_date_birth_date')}
                    </div>
                    <div className="input__wrapper">
                        <ConfigProvider
                            theme={{ token: { colorPrimary: '#bdbdbd', } }}
                            locale={currentLocale}>
                            <DatePicker
                                size={'large'}
                                value={selectedDate}
                                onChange={setSelectedDate}
                                format="YYYY-MM-DD"
                                allowClear={false}
                                showToday={false}
                                inputReadOnly={true}
                            />
                        </ConfigProvider>
                    </div>


                </div>
                <div className="question__wrapper">
                    <div className="label__wrapper">
                        {t('screen_birth_date_pregnancy')}
                    </div>
                    <div className="input__wrapper">
                        <SwitchComponent id="pregnancy"
                                         onCheckHandler={resultsCtx.onPregnancyClick}
                                         checked={resultsCtx.pregnancy}/>
                    </div>
                </div>

                <div className="question__wrapper">
                    <div className="label__wrapper">
                        {t('screen_birth_date_planned_pregnancy')}
                    </div>
                    <div className="input__wrapper">
                        <SwitchComponent id="plannedPregnancy"
                                         onCheckHandler={resultsCtx.onPlannedPregnancyClick}
                                         checked={resultsCtx.plannedPregnancy}/>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default FormBirthDate;
